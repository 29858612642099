body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#wombawe {
    cursor: default;
    font-size: 100px;
    font-family: Inter, sans-serif;
    color: #FFF;
    animation: text-glow 2s ease-in-out infinite alternate;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-fill-mode: both;
    rotate: 0;
    transition: font-size 3s ease-in-out;
    &:hover {
        font-size: 250px;
    }
}

@keyframes text-glow {
    from {
        text-shadow: 0 0 5px rgba(30, 144, 255, 0.8), 0 0 10px rgba(255, 235, 150, 0.5);
    }
    to {
        text-shadow: 0 0 20px rgba(30, 144, 255, 0.8), 0 0 50px rgba(255, 235, 150, 0.5);
    }
}


.dark-mode {
    background: radial-gradient(circle at center,
    #1A1A2E 0%,
    #151529 20%,
    #0F0F23 40%,
    #0A0A1C 60%,
    #050515 80%);
    height: 100vh;
    width: 100vw;
}
