
.particle {
    position: absolute;
    width: 3px;
    height: 2px;
    border-radius: 50%;
    background-color: rgba(255, 235, 150, 0.7);
    animation: particle-move 2s ease-in-out infinite;
}

@keyframes particle-move {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}
